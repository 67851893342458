import { render, staticRenderFns } from "./customize-options.vue?vue&type=template&id=5afa793a&scoped=true"
import script from "./customize-options.vue?vue&type=script&lang=js"
export * from "./customize-options.vue?vue&type=script&lang=js"
import style0 from "./customize-options.vue?vue&type=style&index=0&id=5afa793a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5afa793a",
  null
  
)

export default component.exports